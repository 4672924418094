import { parseISO } from 'date-fns';
import { graphql } from '../../utils/api/apiUtils';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';
import { NonReportedError } from '../../features/error/errorApi';

export interface ListLastUpdated {
  list_id: string;
  last_updated: Date;
}

export const getKnownListIdsFromServer = async (): Promise<ListLastUpdated[]> => {
  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('getKnownListIdsFromServer - start');
  const query = `
        query getKnownListIds {
            getKnownListIds {
              list_id
              last_updated
            }
        }
    `;
  try {
    const result = await graphql<ListLastUpdated[]>(query);
    if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('getKnownListIdsFromServer - end', result.length);
    return result.map((r) => {
      //Comparing the string version of the object to the Date version we load locally was causing major issues
      if (typeof r.last_updated === 'string') {
        r.last_updated = parseISO(r.last_updated);
      }
      return r;
    });
  } catch (error) {
    console.error('Error in getKnownListIdsFromServer:', error);
    if (error instanceof NonReportedError) {
      throw new NonReportedError('Failed to fetch known list IDs from server.');
    }
    throw new Error('Failed to fetch known list IDs from server.');
  }
};
