import isPermittedNetworkConnected from '../../../sync/network/network';
import { graphql } from '../../../utils/api/apiUtils';
import { _setting } from '../../../utils/state/model/implementations/ImplementationFactory';
import { DexieSetting } from '../../../utils/state/model/implementations/dexie/implementationModel/dexieObjects';

export const updateOrganizationSettingsFromServer = async () => {
  isPermittedNetworkConnected().then((isConnected) => {
    if (!isConnected) {
      console.log('No network connection, so we are not updating the organization settings');
      return;
    }

    getSettingsFromServer().then(async (settings) => {
      await updateSettings(settings);

      document.dispatchEvent(new CustomEvent('orgSettingsUpdated', { detail: settings }));
    });
  });
};

const getSettingsFromServer = async (): Promise<DexieSetting[]> => {
  const query = `
        query getOrgSettingsQuery {
            getOrganizationSettings {
                key
                value
            }
        }
    `;
  const settings = await graphql<DexieSetting[]>(query);
  return settings;
};

const updateSettings = async (settings: DexieSetting[]) => {
  await _setting.setSettings(settings);
};
