import { TimeTrackingEventType } from '../../../timetracking/timeTrackingConstants';
import { getDeviceCurrentPosition } from '../../../utils/state/getDeviceCurrentPosition';
import { _timeTracking } from '../../../utils/state/model/implementations/ImplementationFactory';
import { v4 as uuid } from 'uuid';

export const addTimePunch = async (isClockedIn: boolean) => {
  const completionCoordinates = await getDeviceCurrentPosition().catch(() => null);
  const { latitude, longitude } = completionCoordinates?.coords ?? { latitude: null, longitude: null };

  const currentWorkLogId: string | undefined = isClockedIn ? await _timeTracking.getCurrentWorkLogId() : uuid();
  if (isClockedIn && !currentWorkLogId) {
    throw new Error('The user is clocked in but no current work log ID was found');
  }

  /*
      https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript
 
      Intl.DateTimeFormat().resolvedOptions().timeZone gets you the name, 
      but can have variations such as:
      | In Edge it return "Asia/Kolkata" and in Chrome return "Asia/Calcutta" for india. 
    */

  const when = new Date();
  await _timeTracking.queueEvent({
    workLogId: currentWorkLogId!,
    punchType: isClockedIn ? TimeTrackingEventType.CLOCK_OUT : TimeTrackingEventType.CLOCK_IN,
    preciseLat: latitude,
    preciseLong: longitude,
    punchTime: when,
    timeZoneOffset: when.getTimezoneOffset(),
  });
};
