import { addMinutes, getDate, getDay, getMonth, parseISO } from 'date-fns';
import { RRule, Weekday } from 'rrule';
import { Task } from '../../../utils/state/model/interfaces/objects';
import { DateTime } from 'luxon';
import { getNthFromDate } from './calendarUtils';
import { CustomValues } from '../components/CustomRecurrenceSelect';

const recurrenceCache_old: Map<string, Date> = new Map();

export const calculateCurrentDueDate_old = (task: Pick<Task, 'nonRecurringDueDate' | 'recurrence'>): Date | null => {
  if (task.nonRecurringDueDate) return task.nonRecurringDueDate;
  if (!task.recurrence) return null;

  const cachedDueDate = recurrenceCache_old.get(task.recurrence);
  if (cachedDueDate && cachedDueDate > new Date()) {
    return cachedDueDate;
  }

  const nextDueDate: Date = getNextRecurringDueDateAfter_old(task.recurrence, new Date());
  recurrenceCache_old.set(task.recurrence, nextDueDate);
  return nextDueDate;
};

const getNextRecurringDueDateAfter_old = (recurrence: string, after: Date): Date => {
  const rrule: RRule = RRule.fromString(recurrence);
  const nextRecurringDueDate = implementUtcDateToLocal(rrule.after(implementLocalDateToUtc(after))!);
  return nextRecurringDueDate;
};

export const implementLocalDateToUtc = (date: Date) => {
  const timeZoneOffset = date.getTimezoneOffset();
  const utcDate = addMinutes(date, -timeZoneOffset);
  return utcDate;
};

export const implementUtcDateToLocal = (date: Date) => {
  const localDate = DateTime.fromJSDate(date).toUTC().setZone('local', { keepLocalTime: true }).toJSDate();
  return localDate;
};

export const rruleByWeekdayOptions = new Map([
  [0, RRule.SU],
  [1, RRule.MO],
  [2, RRule.TU],
  [3, RRule.WE],
  [4, RRule.TH],
  [5, RRule.FR],
  [6, RRule.SA],
]);

export const getByWeekdayValue = (date: Date) => {
  const day = getDay(date);
  const weekdayNth = getNthFromDate(date);
  return rruleByWeekdayOptions.get(day)?.nth(weekdayNth);
};

export const createRecurrenceRule = (
  ruleOption: RRule,
  option: string,
  date: Date,
  tzid: string = DateTime.local().zoneName!
) => {
  const byweekdayValue = option === 'monthly-weekday' ? getByWeekdayValue(date) : null;
  const newRule = new RRule({
    ...ruleOption.origOptions,
    dtstart: implementLocalDateToUtc(date),
    byweekday: byweekdayValue || ruleOption.origOptions.byweekday,
    tzid: tzid,
  });

  return newRule;
};

export const createCustomRecurrenceRule = (
  rule: RRule | undefined,
  intervalValue: number,
  customValues: CustomValues | undefined
) => {
  if (!rule) return;

  const newRule = new RRule({
    ...rule.origOptions,
    interval: intervalValue,
  });
  // Generate weekly rule
  if (newRule.origOptions.freq === RRule.WEEKLY) {
    if (Array.isArray(customValues) && customValues.length === 0)
      return new RRule({
        ...newRule.origOptions,
        byweekday: undefined,
      });

    return new RRule({
      ...newRule.origOptions,
      byweekday: customValues as Weekday[],
    });
  }
  // Generate monthly rule
  if (newRule.origOptions.freq === RRule.MONTHLY) {
    if (!Array.isArray(customValues)) return;

    if (Array.isArray(customValues) && customValues.length === 0)
      return new RRule({
        ...newRule.origOptions,
        byweekday: undefined,
      });
    if (typeof customValues[0] === 'number') {
      return new RRule({
        ...newRule.origOptions,
        bymonthday: customValues as number[],
      });
    } else {
      return new RRule({
        ...newRule.origOptions,
        byweekday: customValues as Weekday[],
      });
    }
  }
  // Generate yearly rule
  if (newRule.origOptions.freq === RRule.YEARLY) {
    if (typeof customValues === 'string') {
      return new RRule({
        ...newRule.origOptions,
        bymonthday: getDate(parseISO(customValues)),
        bymonth: getMonth(parseISO(customValues)) + 1,
      });
    }
  }

  return newRule;
};
