import { useEffect, useRef, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import AddInputType, { showInputState } from '../features/checklist/AddInputType';
import ListsView from '../features/checklist/lists/ListsView';
import ListsViewSkeletonText from '../features/checklist/lists/ListsViewSkeletonText';
import { _list, _user } from '../utils/state/model/implementations/ImplementationFactory';
import { ListDO, UserDO } from '../utils/state/model/interfaces/displayObjects';
import { sortByCreatedOn, sortByDueDate, sortListsByMostRecentActivity } from '../features/sort/sortUtils';
import useSortOptions, { SortOption } from '../features/sort/useSortOptions';
import { writeAccessRestrictedState } from '../App';
import SortOptions from '../components/SortOptions';
import PullToRefresh from '../features/general/PullToRefresh';
import { hasDownloadedFirstBatchState, syncState } from '../sync/sync';
import { useTranslation } from 'react-i18next';
import KeyboardSpacer from '../components/KeyboardSpacer';
import useGetLocalData, { SubscriptionsType } from '../hooks/useGetLocalData';
import { multiSelectListItemsState } from '../features/checklist/lists/state/useMultiSelectLists';
import ListMultiSelectActionSheet from '../features/checklist/lists/ListMultiSelectActionSheet';
import { AuthCache, UserGroups } from '@otuvy/auth';
import AddItemFab from '../features/general/AddItemFab';
import { isRenameListInputOnState } from '../features/checklist/lists/ListPreview';
import useNetworkStatus from '../sync/network/useNetworkStatus';
import { WriteAccessRestrictedReason } from '../utils/useCheckOrgGovernors';
import { EnvironmentConfig, getFlag } from '../utils/environmentUtils';

const SKELETON_UI_TIMEOUT = 10000;

interface ListGroups {
  completeLists: ListDO[];
  incompleteLists: ListDO[];
  archivedLists: ListDO[];
}

export const listMultiSelectModeAtom = atom<boolean>({ key: 'listMultiSelectModeAtom', default: false });

const ListsPage: React.FC = () => {
  const [isActiveView, setIsActiveView] = useState<boolean | null>(null);
  const { isRestricted: isWriteAccessRestricted } = useRecoilValue(writeAccessRestrictedState);
  const hasDownloadedFirstBatch = useRecoilValue(hasDownloadedFirstBatchState);
  const { isOnline } = useNetworkStatus();
  const showInput = useRecoilValue(showInputState);
  const isRenameListInputOn = useRecoilValue(isRenameListInputOnState);
  const [isMultiSelectMode, setIsMultiSelectMode] = useRecoilState(listMultiSelectModeAtom);
  const [multiSelectedItems, setMultiSelectedItems] = useRecoilState(multiSelectListItemsState);

  const [searchListValue, setSearchListValue] = useState<string>('');
  const [skeletonUiTimedOut, setSkeletonUiTimedOut] = useState<boolean>(false);

  // Set isActiveView to true when entering the view and false when leaving
  // This is used to determine whether to show the AddInputType component or not
  // To determine if we should show the AddInputType component we use the same global state (showInput)
  // which was causing the input to show even if this view was not the active view
  // This was causing the input to loose focus as soon as it rendered in the list details view, closing itself
  // This probably was not happening before due to a side effect but it should not have worked that way
  useIonViewWillEnter(() => {
    setIsActiveView(true);
  }, []);

  useIonViewWillLeave(() => {
    setIsActiveView(false);
  }, []);

  const { listData: lists, loading, currentUserData } = useGetListPageData();
  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const { t } = useTranslation();
  const {
    sortOption: incompleteSortOption,
    setSortOption: setIncompleteSortOption,
    areSortOptionsOpen: areIncompleteSortOptionsOpen,
    setAreSortOptionsOpen: setAreIncompleteSortOptionsOpen,
  } = useSortOptions('lists/incomplete');
  const {
    sortOption: completedSortOption,
    setSortOption: setCompletedSortOption,
    areSortOptionsOpen: areCompletedSortOptionsOpen,
    setAreSortOptionsOpen: setAreCompletedSortOptionsOpen,
  } = useSortOptions('lists/completed');
  const {
    sortOption: archivedSortOption,
    setSortOption: setArchivedSortOption,
    areSortOptionsOpen: areArchivedSortOptionsOpen,
    setAreSortOptionsOpen: setAreArchivedSortOptionsOpen,
  } = useSortOptions('lists/archived');

  const setWriteAccessRestricted = useSetRecoilState(writeAccessRestrictedState);

  useEffect(() => {
    setTimeout(() => setSkeletonUiTimedOut(true), SKELETON_UI_TIMEOUT);
  }, []);

  const filterList = (list: ListDO) => {
    const allowedByRole =
      (isMultiSelectMode &&
        (list.owner === currentUserData?.userId ||
          AuthCache.isCurrentUserInAnyGroup([
            UserGroups.LIST_MANAGER,
            UserGroups.SUPER_ADMIN,
            UserGroups.OTUVY_TECH_SUPPORT,
          ]))) ||
      !isMultiSelectMode;

    const allowedByFilter = list.listName.toLowerCase().includes(searchListValue.toLowerCase());
    return allowedByRole && allowedByFilter;
  };

  const sortLists = (): ListGroups => {
    if (!lists) return { completeLists: [], incompleteLists: [], archivedLists: [] };
    const filteredIncompleteLists = lists.incompleteLists.filter(filterList);
    const filteredCompleteLists = lists.completeLists.filter(filterList);
    const filteredArchivedLists = lists.archivedLists.filter(filterList);

    if (!filteredIncompleteLists.length && !filteredCompleteLists.length && !filteredArchivedLists.length)
      return { completeLists: [], incompleteLists: [], archivedLists: [] };

    const sortArrayOfLists = (arr: ListDO[], sortOption: SortOption | undefined): ListDO[] => {
      switch (sortOption) {
        case SortOption.NAME:
          return [...arr].sort((a, b) => a.listName.localeCompare(b.listName));
        case SortOption.CREATION_DATE:
          return sortByCreatedOn(arr);
        case SortOption.DUE_DATE:
          return sortByDueDate(arr);
        case SortOption.RECENT_ACTIVITY:
          return sortListsByMostRecentActivity(arr);
        //SortOption.ASSIGNED_TO explicitly not implemented at this point
        default:
          return sortByCreatedOn(arr);
      }
    };

    return {
      completeLists: sortArrayOfLists(filteredCompleteLists, completedSortOption),
      incompleteLists: sortArrayOfLists(filteredIncompleteLists, incompleteSortOption),
      archivedLists: sortArrayOfLists(lists.archivedLists, archivedSortOption),
    };
  };
  const sortedLists = sortLists();

  const onSearchListChange = (value: string) => {
    setSearchListValue(value);
  };

  const numLists = lists ? lists.incompleteLists.length + lists.completeLists.length + lists.archivedLists.length : 0;
  if (
    !skeletonUiTimedOut && // Avoid infinite skeleton UI
    (loading ||
      // If there are no lists on device, show skeleton UI until it has downloaded its first batch (even if it's empty)
      (numLists === 0 && !hasDownloadedFirstBatch && isOnline)) // Don't hold them hostage if they're offline
  ) {
    return (
      <IonPage>
        <IonHeader></IonHeader>
        <IonContent ref={ionContentRef} scrollY={true} forceOverscroll>
          <ListsViewSkeletonText />
        </IonContent>
      </IonPage>
    );
  }

  return (
    <>
      <IonPage>
        {isMultiSelectMode && (
          <IonHeader>
            <IonToolbar style={{ '--ion-safe-area-top': '0px' }}>
              <IonButtons slot="start">
                <IonButton
                  onClick={() => {
                    if (
                      multiSelectedItems.length ===
                      [...sortedLists.incompleteLists, ...sortedLists.completeLists, ...sortedLists.archivedLists]
                        .length
                    ) {
                      setMultiSelectedItems([]);
                    } else {
                      setMultiSelectedItems([
                        ...sortedLists.incompleteLists,
                        ...sortedLists.completeLists,
                        ...sortedLists.archivedLists,
                      ]);
                    }
                  }}
                >
                  {multiSelectedItems.length ===
                  [...sortedLists.incompleteLists, ...sortedLists.completeLists, ...sortedLists.archivedLists].length
                    ? t('deselectAll')
                    : t('selectAll')}
                </IonButton>
              </IonButtons>
              <IonTitle className="font-32">{t('lists')}</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  onClick={() => {
                    setIsMultiSelectMode(false);
                    setMultiSelectedItems([]);
                  }}
                >
                  {t('done')}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent ref={ionContentRef} scrollY={true} forceOverscroll>
          {getFlag(EnvironmentConfig.TEST_WRITE_RESTRICTION) && (
            <>
              <div>
                <button
                  onClick={() =>
                    setWriteAccessRestricted({ isRestricted: true, reason: WriteAccessRestrictedReason.NON_PAYMENT })
                  }
                >
                  Non-payment
                </button>
              </div>
              <div>
                <button
                  onClick={() =>
                    setWriteAccessRestricted({
                      isRestricted: true,
                      reason: WriteAccessRestrictedReason.MISSED_REQUIRED_UPDATE,
                    })
                  }
                >
                  Missed update
                </button>
              </div>
              <div>
                <button onClick={() => setWriteAccessRestricted({ isRestricted: false, reason: null })}>
                  Not restricted
                </button>
              </div>
            </>
          )}
          <PullToRefresh />
          <ListsView
            ionContentRef={ionContentRef.current?.shadowRoot?.querySelector('.inner-scroll')}
            completedLists={sortedLists.completeLists}
            incompleteLists={sortedLists.incompleteLists}
            archivedLists={sortedLists.archivedLists}
            onSearchListChange={onSearchListChange}
            searchListValue={searchListValue}
            setAreIncompleteSortOptionsOpen={setAreIncompleteSortOptionsOpen}
            setAreCompletedSortOptionsOpen={setAreCompletedSortOptionsOpen}
            setAreArchivedSortOptionsOpen={setAreArchivedSortOptionsOpen}
          />
          {!isWriteAccessRestricted && !showInput && !isRenameListInputOn && !isMultiSelectMode ? (
            <AddItemFab type="list" />
          ) : null}
        </IonContent>
        <KeyboardSpacer />
        {lists && showInput && isActiveView && (
          <>
            <AddInputType inputType="addList" />
          </>
        )}
        <ListMultiSelectActionSheet />
      </IonPage>

      <SortOptions
        isOpen={areIncompleteSortOptionsOpen}
        setSortOption={setIncompleteSortOption}
        setAreSortOptionsOpen={setAreIncompleteSortOptionsOpen}
        excludedSortOptions={[SortOption.ASSIGNED_TO]}
      />

      <SortOptions
        isOpen={areCompletedSortOptionsOpen}
        setSortOption={setCompletedSortOption}
        setAreSortOptionsOpen={setAreCompletedSortOptionsOpen}
        excludedSortOptions={[SortOption.DUE_DATE, SortOption.ASSIGNED_TO]}
      />
      <SortOptions
        isOpen={areArchivedSortOptionsOpen}
        setSortOption={setArchivedSortOption}
        setAreSortOptionsOpen={setAreArchivedSortOptionsOpen}
        excludedSortOptions={[SortOption.DUE_DATE, SortOption.ASSIGNED_TO]}
      />
    </>
  );
};

export default ListsPage;

export function useGetListPageData() {
  const isSyncing = useRecoilValue(syncState);
  const { data: listData, loading } = useGetLocalData(getListPageData, [isSyncing], SubscriptionsType.AnyListChanges);
  const { data: currentUserData } = useGetLocalData(getCurrentUserData, [isSyncing], SubscriptionsType.AnyListChanges);

  async function getListPageData() {
    const listsData = await _list.getAllVisibleLists();

    if (!listsData) throw new Error('No lists data found!');

    const complete: ListDO[] = [];
    const incomplete: ListDO[] = [];
    const archive: ListDO[] = [];

    listsData.forEach((list) => {
      if (list.isArchived) {
        archive.push(list);
      } else if (list.isCompleted) {
        complete.push(list);
      } else {
        incomplete.push(list);
      }
    });

    return { completeLists: complete, incompleteLists: incomplete, archivedLists: archive };
  }

  async function getCurrentUserData(): Promise<UserDO | undefined> {
    const doesUserExist = await _user.doesUserExist(AuthCache.getCurrentUserId()!);
    if (!doesUserExist) return;
    const userData = await _user.fetchCurrentUser();
    if (!userData) throw new Error('No user data found!');
    return userData;
  }

  return { listData, loading, currentUserData };
}
