import { IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonLabel, IonPage, IonRow, IonText } from '@ionic/react';
import ClockInButton from '../features/timeTracking/components/ClockInButton';
import WeeklyBreakdownItem from '../features/timeTracking/components/WeeklyBreakdownItem';
import { WorkLogData } from '../timetracking/timeTrackingConstants';
import { _timeTracking } from '../utils/state/model/implementations/ImplementationFactory';
import { isDateObject } from '../features/sort/sortUtils';
import {
  differenceInMilliseconds,
  endOfDay,
  endOfWeek,
  intervalToDuration,
  isSameDay,
  isSameWeek,
  isWithinInterval,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import { formatTime } from '../features/timeTracking/utils/timeUtils';
import useGetLocalData, { SubscriptionsType } from '../hooks/useGetLocalData';
import { useRecoilValue } from 'recoil';
import { syncState } from '../sync/sync';

const TimeTrackingPage: React.FC = () => {
  const isSyncing = useRecoilValue(syncState);

  const getTimeTrackingPageData = async () => {
    const workLogs = await _timeTracking.getWorkLogs();
    const sortedWorkLogs = sortWorkLogsByPunchtime(workLogs);
    return sortedWorkLogs;
  };

  const { data: workLogs } = useGetLocalData(
    getTimeTrackingPageData,
    [isSyncing],
    SubscriptionsType.TimeTrackingChanges
  );

  const sortWorkLogsByPunchtime = (arr: WorkLogData[]): WorkLogData[] => {
    return [...arr].sort((a, b) => {
      if (!a.punchIn?.punchTime && !b.punchIn?.punchTime) {
        return 0;
      }
      if (!a.punchIn?.punchTime) {
        return 1;
      }
      if (!b.punchIn?.punchTime) {
        return -1;
      }

      const aDate = isDateObject(a.punchIn.punchTime).getTime();
      const bDate = isDateObject(b.punchIn.punchTime).getTime();

      /*
        If this is 0 we could additionally sort by punch out time.
        For a single user history that *should* not happen, 
        but it might for the multi user hisotry
      */
      return aDate - bDate;
    });
  };

  return (
    <IonPage>
      <IonHeader></IonHeader>
      <IonContent className="ion-padding">
        {/* Clock In/Out Button */}
        <div
          className="d-flex ion-justify-content-center"
          style={{ marginTop: '48px', marginBottom: '48px', flexWrap: 'wrap' }}
        >
          <ClockInButton />
        </div>

        {!workLogs ? null : ( //TODO: show something like a loading spinner if it starts to take a while to load
          <>
            {/* Daily Stats */}
            <div style={{ margin: '0 auto', marginBottom: '60px', maxWidth: '500px' }}>
              {/* Daily clock in/out history */}
              {/* Example UI for the daily history component*/}
              <CurrentDayWorkLogHistory workLogs={workLogs} />

              {/* Daily total hours worked | Testing with week one next to it */}
              <div className="d-flex ion-justify-content-around ion-align-items-center">
                <div
                  className="d-flex ion-justify-content-center ion-align-items-center ion-text-center"
                  style={{ flexDirection: 'column' }}
                >
                  <div>
                    <IonLabel className="font-24 font-weight-600">Daily Total</IonLabel>
                  </div>
                  <div>
                    <IonText className="font-20 ion-margin-end">Hours:</IonText>
                    <IonText className="font-20">{getDailyTotalHours(workLogs)}</IonText>
                  </div>
                </div>
                <div
                  className="d-flex ion-justify-content-center ion-align-items-center ion-text-center"
                  style={{ flexDirection: 'column' }}
                >
                  <div>
                    <IonLabel className="font-24 font-weight-600">Weekly Total</IonLabel>
                  </div>
                  <div>
                    <IonText className="font-20 ion-margin-end">Hours:</IonText>
                    <IonText className="font-20">{getWeeklyTotalHours(workLogs)}</IonText>
                  </div>
                </div>
              </div>
            </div>

            {/* Weekly Stats */}
            <div style={{ maxWidth: '500px', margin: '0 auto' }}>
              {/* Weekly total hours worked */}
              {/* <div
                className="d-flex ion-justify-content-center ion-align-items-center ion-text-center"
                style={{ flexDirection: 'column', marginBottom: '40px' }}
              >
                <div>
                  <IonLabel className="font-24 font-weight-600">Weekly Total</IonLabel>
                </div>
                <div>
                  <IonText className="font-20 ion-margin-end">Hours:</IonText>
                  <IonText className="font-20">32:35</IonText>
                </div>
              </div> */}

              {/* Weekly Breakdown */}
              <div>
                <div className="ion-text-start ion-padding-horizontal d-flex ion-align-items-center ion-justify-content-center">
                  <IonLabel className="font-24 font-weight-600 ion-text-center">Week Breakdown</IonLabel>
                  {/* <div>
                    <IonIcon
                      className="font-24"
                      style={{ marginLeft: '8px' }}
                      icon={add}
                      />
                  </div> */}
                </div>

                {/* Records */}
                <WeeklyBreakdownEditableGrid workLogs={workLogs} />
              </div>
            </div>
          </>
        )}
      </IonContent>
      <IonFooter></IonFooter>
    </IonPage>
  );
};

export default TimeTrackingPage;

interface WeeklyBreakdownEditableGridProps {
  workLogs: WorkLogData[];
}

// TODO: Move to own file | If component does not grow, delete the component and add the jsx to parent component
const WeeklyBreakdownEditableGrid: React.FC<WeeklyBreakdownEditableGridProps> = (
  params: WeeklyBreakdownEditableGridProps
) => {
  const now = new Date();
  const timeTrackingRecordsThisWeek: WorkLogData[] = params.workLogs.filter((workLog) => {
    if (!workLog.punchIn?.punchTime) return false;
    return isSameWeek(workLog.punchIn.punchTime, now);
  });

  return (
    <IonGrid>
      {/* Header */}
      <IonRow style={{ background: 'var(--toolbar-background)' }}>
        <IonCol className="ion-text-center">
          <IonText style={{ textTransform: 'capitalize' }} className="font-16 font-weight-600 ion-text-uppercase">
            Start Date
          </IonText>
        </IonCol>
        <IonCol className="ion-text-center">
          <IonText style={{ textTransform: 'capitalize' }} className="font-16 font-weight-600 ion-text-uppercase">
            Start Time
          </IonText>
        </IonCol>
        <IonCol className="ion-text-center">
          <IonText style={{ textTransform: 'capitalize' }} className="font-16 font-weight-600 ion-text-uppercase">
            End Date
          </IonText>
        </IonCol>
        <IonCol className="ion-text-center">
          <IonText style={{ textTransform: 'capitalize' }} className="font-16 font-weight-600 ion-text-uppercase">
            End Time
          </IonText>
        </IonCol>
      </IonRow>

      {/* Records */}
      {timeTrackingRecordsThisWeek.map((record, index) => (
        <WeeklyBreakdownItem
          key={`weeklyBreakdownGridItem-${record.workLogId}`}
          timeTrackingRecord={record}
          index={index}
        />
      ))}
    </IonGrid>
  );
};

interface CurrentDayWorkLogHistoryProps {
  workLogs: WorkLogData[];
}

const CurrentDayWorkLogHistory: React.FC<CurrentDayWorkLogHistoryProps> = ({ workLogs }) => {
  const today = new Date();
  const todaysWorkLogs: WorkLogData[] = workLogs.filter((workLog) => {
    const logDate = new Date(workLog.punchIn?.punchTime!);
    return isSameDay(today, logDate);
  });

  return (
    <IonGrid className="ion-margin-bottom">
      {todaysWorkLogs.map((workLog: WorkLogData) => (
        <div key={workLog.workLogId}>
          {workLog.punchIn?.punchTime && (
            <IonRow className="d-flex ion-align-items-center ion-justify-content-around">
              <IonCol>
                <IonLabel className="font-24">Clocked In At:</IonLabel>
              </IonCol>
              <IonCol className="ion-text-end">
                <IonText className="font-24">{formatTime(workLog.punchIn.punchTime)}</IonText>
              </IonCol>
            </IonRow>
          )}
          {workLog.punchOut?.punchTime && (
            <IonRow className="d-flex ion-align-items-center ion-justify-content-around">
              <IonCol>
                <IonLabel className="font-24">Clocked Out At:</IonLabel>
              </IonCol>
              <IonCol className="ion-text-end">
                <IonText className="font-24">{formatTime(workLog.punchOut.punchTime)}</IonText>
              </IonCol>
            </IonRow>
          )}
        </div>
      ))}
    </IonGrid>
  );
};

const formatDuration = (milliseconds: number): string => {
  const duration = intervalToDuration({ start: 0, end: milliseconds });
  const hours = duration.hours! + duration.days! * 24;
  const minutes = duration.minutes!;
  return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

const getDailyTotalHours = (workLogs: WorkLogData[]): string => {
  if (!workLogs || workLogs.length < 1) return '--:--';
  const dayStart = startOfDay(new Date());
  const dayEnd = endOfDay(new Date());

  const totalMilliseconds = workLogs
    .filter(
      (log) =>
        log.punchIn?.punchTime &&
        log.punchOut?.punchTime &&
        isWithinInterval(new Date(log.punchIn.punchTime), { start: dayStart, end: dayEnd })
    )
    .reduce((total, log) => {
      const difference = differenceInMilliseconds(
        new Date(log.punchOut?.punchTime!),
        new Date(log.punchIn?.punchTime!)
      );
      const roundedMilliseconds = Math.ceil(difference / 60000) * 60000;
      return total + roundedMilliseconds;
    }, 0);

  return formatDuration(totalMilliseconds);
};

const getWeeklyTotalHours = (workLogs: WorkLogData[]): string => {
  if (!workLogs || workLogs.length < 1) return '--:--';
  const weekStart = startOfWeek(new Date());
  const weekEnd = endOfWeek(new Date());

  const totalMilliseconds = workLogs
    .filter(
      (log) =>
        log.punchIn?.punchTime &&
        log.punchOut?.punchTime &&
        isWithinInterval(new Date(log.punchIn.punchTime), { start: weekStart, end: weekEnd })
    )
    .reduce((total, log) => {
      const difference = differenceInMilliseconds(
        new Date(log.punchOut?.punchTime!),
        new Date(log.punchIn?.punchTime!)
      );
      const roundedMilliseconds = Math.ceil(difference / 60000) * 60000;
      return total + roundedMilliseconds;
    }, 0);

  return formatDuration(totalMilliseconds);
};
