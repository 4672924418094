import { AuthCache, getIDTokenAsString } from '@otuvy/auth';
import { TIME_TRACKING_API_BASE_URL } from '../../aws-exports';
import { WorkLogData } from '../../timetracking/timeTrackingConstants';
import { streamToString } from '../../utils/api/apiUtils';
import isPermittedNetworkConnected from '../network/network';
import { _timeTracking } from '../../utils/state/model/implementations/ImplementationFactory';
import { parseISO } from 'date-fns';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';

const DAYS_OF_DATA_TO_DOWNLOAD = 7;
const daysInMilli = DAYS_OF_DATA_TO_DOWNLOAD * 24 * 60 * 60 * 1000;

export async function downloadWorkLogsForCurrentUser(): Promise<void> {
  if (!getFlag(EnvironmentConfig.TIME_TRACKING)) return;

  const untransformedWorkLogs = await getWorkLogsForCurrentUser();
  const workLogs = parseWorkLogs(untransformedWorkLogs);
  _timeTracking.saveWorkLogs(workLogs);
}

async function getWorkLogsForCurrentUser(): Promise<any[]> {
  if (!getFlag(EnvironmentConfig.TIME_TRACKING)) return []; //Touch for redeploy

  if (!(await isPermittedNetworkConnected())) {
    console.log('Not permitted to sync in this network state! (getWorkLogsForCurrentUser)');
    return [];
  }

  const currentUserID = AuthCache.getCurrentUserId();

  const now = new Date();

  const begin = new Date(now.getTime() - daysInMilli);

  const idToken: string | undefined = await getIDTokenAsString();
  if (!idToken) {
    console.error('No ID Token');
    throw new Error('No ID Token');
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Id: idToken,
    },
    body: JSON.stringify({
      userIds: [currentUserID],
      startDate: begin.toISOString(),
      endDate: now.toISOString(),
    }),
  };

  const url = TIME_TRACKING_API_BASE_URL + 'download-work-log-data';
  const response: Response = await fetch(url, requestOptions);

  if (response.status !== 200) {
    console.error(`Error fetching work logs: ${response.statusText}`);
  } else if (response.body) {
    const body = await streamToString(response.body);
    const data = JSON.parse(body);
    if (data && data.length === 1) {
      return data[0].workLogs;
    } else if (data && data.length > 1) {
      console.warn(`Expected a single user's work logs.  Instead we got ${data.length} users`);
    } else {
      //No work logs for the current user
    }
  }

  return [];
}

const parseWorkLogs = (untransformedWorkLogs: any[]): WorkLogData[] => {
  return untransformedWorkLogs.map<WorkLogData>((workLog) => {
    return {
      ...workLog,
      punchIn: {
        ...workLog.punchIn,
        punchTime: parseISO(workLog.punchIn.punchTime),
      },
      punchOut: !workLog.punchOut
        ? undefined
        : {
            ...workLog.punchOut,
            punchTime: workLog.punchOut.punchTime ? parseISO(workLog.punchOut.punchTime) : null,
          },
    };
  });
};
