import isPermittedNetworkConnected from './network/network';
import { uploadChanges } from './syncToServer/uploadChanges';
import { confirmChanges } from './syncToServer/confirmChanges';
import { updateDataFromServer } from './syncFromServer/downloadChanges';
import { isCurrentUserAuthenticated } from '@otuvy/auth';
import { _photo, _timeTracking } from '../utils/state/model/implementations/ImplementationFactory';
import { uploadAnalytics } from './syncToServer/uploadAnalytics';
import { logFriendlyObject } from '@otuvy/common-utils';
import { EnvironmentConfig, getFlag } from '../utils/environmentUtils';
import { atom, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { uploadTimeTracking } from './syncToServer/uploadTimeTracking';
import { downloadWorkLogsForCurrentUser } from './syncFromServer/downloadWorkLogs';

//To signal to the sync process that we have aborted syncing and that no further syncing should happen
export class SyncAborted extends Error {
  constructor(message?: any) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class forceRecords {
  forceSyncLists?: string[] = [];
  forceSyncAllLists?: boolean = false;
}

export class SyncParams extends forceRecords {
  confirmProcessed?: boolean = false;
}

let isSyncInProgress = false;

/**
 * @returns `true` if sync finished successfully, `false` if sync was aborted, and throws an error if sync failed
 */
export async function syncData(params?: SyncParams): Promise<boolean> {
  if (!getFlag(EnvironmentConfig.SERVER_SYNC)) return false;

  try {
    if (!(await isPermittedNetworkConnected())) {
      console.log('Not permitted to sync in this network state! (syncData)');
      throw new SyncAborted('No network access');
    }

    if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('syncData - checking prerequisites');
    const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();
    if (!isUserAuthenticated) {
      console.log('syncData - no authenticated user.  Aborting');
      throw new SyncAborted('No authenticated user');
    }

    if (isSyncInProgress) throw new SyncAborted('Sync already in progress');

    try {
      isSyncInProgress = true;
      document.dispatchEvent(new CustomEvent('syncData', { detail: { isSyncing: true } }));
      console.log('syncData - start');

      await uploadChanges();

      //TODO: verify user is authenticated before downloading changes

      await updateDataFromServer(params);
      //Confirm AFTER getting update data to make sure any locally pending (namely delete) operations finish before considering them finalized
      if (!params || params.confirmProcessed === undefined || params.confirmProcessed) {
        await confirmChanges();
      }

      await uploadAnalytics();

      await uploadTimeTracking();
      await downloadWorkLogsForCurrentUser();

      console.log('syncData - cleanup');

      _timeTracking.cleanupWorkLogs();
      _photo.cleanUpImageCache();

      console.log('syncData - end');
    } catch (error) {
      console.warn('sync failed', logFriendlyObject(error));
    } finally {
      document.dispatchEvent(new CustomEvent('syncData', { detail: { isSyncing: false } }));
      isSyncInProgress = false;
    }
  } catch (error: any) {
    if (error instanceof SyncAborted) {
      console.log('Sync aborted', error.message);
      return false;
    }
    throw error;
  }

  return true;
}

export const syncState = atom<boolean>({
  key: 'syncState',
  default: false,
});

export const useSyncState = () => {
  const setIsSyncing = useSetRecoilState(syncState);

  useEffect(() => {
    const callback = ((e: CustomEvent) => {
      if (e.detail.isSyncing) {
        //Put this in a condition to coerce it to boolean in case it's not
        setIsSyncing(true);
      } else {
        setIsSyncing(false);
      }
    }) as EventListener;

    document.addEventListener('syncData', callback);
    return () => {
      document.removeEventListener('syncData', callback);
    };
  }, []);
};

export const hasDownloadedFirstBatchState = atom<boolean>({
  key: 'hasDownloadedFirstBatchState',
  default: false,
});

export const useHasDownloadedFirstBatchState = () => {
  const setHasDownloadedFirstBatch = useSetRecoilState(hasDownloadedFirstBatchState);

  useEffect(() => {
    const callback = ((e: CustomEvent) => {
      if (e.detail.hasDownloadedFirstBatch) {
        //Put this in a condition to coerce it to boolean in case it's not
        setHasDownloadedFirstBatch(true);
      } else {
        setHasDownloadedFirstBatch(false);
      }
    }) as EventListener;

    document.addEventListener('downloadedFirstBatch', callback);
    return () => {
      document.removeEventListener('downloadedFirstBatch', callback);
    };
  }, []);
};
