import React, { useEffect, useState } from 'react';

import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import SignIn from './features/login/SignIn';
import App from './App';
import awsconfig from './aws-exports';
import { useRecoilValue } from 'recoil';
import { IonContent, IonPage } from '@ionic/react';
import useToastPresenter from './utils/useToastPresenter';
import { _datasource } from './utils/state/model/implementations/ImplementationFactory';
import { logFriendlyObject } from '@otuvy/common-utils';
import { isPermittedNetworkConnectedState } from './sync/network/network';
import { AuthCache, isCurrentUserAuthenticated } from '@otuvy/auth';

Amplify.configure(awsconfig); //in order to implement basic GDPR compliance by changing Regions the data is stored in we can pass in different configurations to this (one for US or one for Europe, etc).
//Amplify.Logger.LOG_LEVEL = 'DEBUG'

enum LoginStatus {
  LOADING,
  LOGGED_IN,
  NOT_LOGGED_IN,
}

const SecureApp: React.FC = () => {
  const [loginStatus, setLoginStatus] = useState<LoginStatus>(LoginStatus.LOADING);
  const isOnline = useRecoilValue(isPermittedNetworkConnectedState);
  useToastPresenter();

  useEffect(() => {
    updateLoginStatus();

    const stopListener = Hub.listen('auth', async (data) => {
      switch (
        data.payload.event // See https://docs.amplify.aws/react/build-a-backend/auth/auth-events/#listen-to-and-log-auth-events for list of auth events
      ) {
        case 'signedIn':
          await updateLoginStatus(); //Once they log in we want to "freshen" our status flags
          await _datasource.postSignIn();
          break;
        case 'signedOut':
          AuthCache.invalidateCache();
          await _datasource.postSignOut(); //the best practice is to clear the local database on logout in the auth event listener
          await updateLoginStatus();
          break;
        case 'tokenRefresh':
          //Huzzah on refreshing the token.  There is nothing to do about it, except update the user's status
          await updateLoginStatus();
          break;
        case 'tokenRefresh_failure':
          if (loginStatus === LoginStatus.NOT_LOGGED_IN) {
            //It looks like we are getting these errors on the sign-in page, which is ok when there is not a current user/session to refresh
          } else {
            console.error('Signed in but also token refresh failure');
            //TODO: Find out what is causing these and whether it is critical.  They may be harmless
          }
          break;
        case 'signInWithRedirect':
          break;
        case 'signInWithRedirect_failure':
          break;
        case 'customOAuthState':
          break;
        default:
          console.warn('Detected unhandled Authentication event', data);
          break;
      }
    });

    return () => {
      stopListener();
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      _datasource.onOffline();
    } else {
      _datasource.onOnline();
    }
  }, [isOnline]);

  const updateLoginStatus = async () => {
    try {
      if (!(await isCurrentUserAuthenticated())) {
        setLoginStatus(LoginStatus.NOT_LOGGED_IN);
        return;
      }

      await AuthCache.refresh();
      await initDatabase();
      setLoginStatus(LoginStatus.LOGGED_IN);
    } catch (exception) {
      if (exception === 'The user is not authenticated') {
        setLoginStatus(LoginStatus.NOT_LOGGED_IN);
      } else if (exception === 'No current user') {
        setLoginStatus(LoginStatus.NOT_LOGGED_IN);
      } else {
        console.error(
          "Unhandled exception fetching current user's session while updating login status",
          logFriendlyObject(exception)
        );
        setLoginStatus(LoginStatus.NOT_LOGGED_IN);
      }
    }
  };

  const initDatabase = async () => {
    await _datasource.init();
  };

  const retComponent = (): JSX.Element => {
    switch (loginStatus) {
      case LoginStatus.LOADING:
        return <></>;
      case LoginStatus.NOT_LOGGED_IN:
        return <SignIn />;
      default:
        return <App />;
    }
  };

  return (
    <IonPage>
      <IonContent>{retComponent()}</IonContent>
    </IonPage>
  );
};

export default SecureApp;
