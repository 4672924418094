import environment from '../environment.json';

export const getFlag = (flagKey: string): boolean => {
  if (!environment.hasOwnProperty('environmentConfig')) return false;

  const flagValue = (environment as any)['environmentConfig'][flagKey];
  if (flagValue) return true;

  return false;
};

export const getNumber = (key: string, defaultValue?: number): number => {
  if (!environment.hasOwnProperty('environmentConfig') || !environment.environmentConfig.hasOwnProperty(key)) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    throw new Error('Failed to get number from environmentConfig and no default value was provided');
  }

  const value = (environment as any)['environmentConfig'][key];
  if (value === undefined) throw new Error('Failed to get number from environmentConfig');

  return value;
};

export const getString = (key: string): string | undefined => {
  if (!environment.hasOwnProperty('environmentConfig')) throw new Error('Failed to get string from environmentConfig');

  const value = (environment as any)['environmentConfig'][key];
  if (value === undefined) {
    return undefined;
  }

  return value;
};

export enum EnvironmentConfig {
  ENVIRONMENT_NAME = 'environmentName',

  // Address/ID flags
  APP_DOMAIN = 'appDomain',
  ADMIN_PORTAL_DOMAIN = 'adminPortalDomain',
  ONESIGNAL_APP_ID = 'onesignalAppId',

  // Sync flags
  SERVER_SYNC = 'serverSync',
  SYNC_INTERVAL_IN_MS = 'syncIntervalInMs',
  SYNC_DOWNLOAD_LIST_BATCH_SIZE = 'syncDownloadListBatchSize',

  // API check flags
  CHECK_FOR_SOFTWARE_UPDATES = 'checkForSoftwareUpdates',
  CHECK_AGREEMENTS = 'checkAgreements',
  CHECK_GOVERNORS = 'checkGovernors',

  // Analytics flags
  ANALYTICS_TRACKING = 'analyticsTracking',

  // Image flags
  ALLOW_PROFILE_PICS_FROM_FILE_SYSTEM = 'allowProfilePicsFromFileSystem',
  SHOW_IMAGE_COMPRESSION_PROGRESS = 'showImageCompressionProgress',
  TIME_TO_KEEP_CACHE_IN_MS = 'timeToKeepCacheInMs',

  // Logging flags
  ON_DEVICE_CONSOLE = 'onDeviceConsole',
  VERBOSE_SYNC_LOGS = 'verboseSyncLogs',
  CAPTURE_LOGS = 'captureLogs',

  // Debug flags
  SKIP_CALCULATING_COMPLETION = 'skipCalculatingCompletion',

  // Temporary flags
  DISPLAY_SYNC_INDICATOR = 'displaySyncIndicator',
  NOTIFICATIONS = 'notifications',
  ORG_SETTINGS = 'orgSettings',
  LIST_OWNER_UI = 'listOwnerUI',

  TIME_TRACKING = 'timeTracking',
  TEST_WRITE_RESTRICTION = 'testWriteRestriction',
}
