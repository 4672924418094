import { generateClient, GraphQLResult } from 'aws-amplify/api';
import { AuthCache, getIDTokenAsString, isCurrentUserAuthenticated } from '@otuvy/auth';
import { logFriendlyObject } from '@otuvy/common-utils';
import { logOutWithTeardown } from '../auth/authTearDown';
import { SyncAborted } from '../../sync/sync';
import { _user } from '../state/model/implementations/ImplementationFactory';
import { excludedErrorMessages, NonReportedError } from '../../features/error/errorApi';

const client = generateClient();

export const graphql = async <T>(query: string, variables?: object): Promise<T> => {
  try {
    const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();
    if (!isUserAuthenticated) {
      throw new SyncAborted('No authenticated user');
    }

    const idToken: string | undefined = await getIDTokenAsString();
    if (!idToken) {
      throw new SyncAborted('No ID Token');
    }

    let targetLanguage = 'en';

    //The settings react hook is not available to this utility, so we have to come up with the language on our own

    //TODO: cache the language or easier to get to so we do not need to make a DB call every time
    try {
      const userId: string | undefined = AuthCache.getCurrentUserId();
      const user = userId
        ? await _user.getById(userId)
        : { userId, language: 'en' /* TODO: use the device language rather than this default language  */ };

      targetLanguage = user.language;
    } catch (e) {
      //This can happen on initial sign in and is OK then
      console.log('Could not get user language, defaulting to English', e);
    }

    const result = (await client.graphql({ query, variables }, { id: idToken, targetLanguage })) as GraphQLResult;

    if (!result.data) {
      throw new Error('No data returned from query');
    }
    const data = result.data as any;
    const keys = Object.keys(data);
    return data[keys[0]]; // This only works if there is only one query/mutation included in the query
  } catch (e: any) {
    if (e instanceof SyncAborted) {
      throw e;
    }

    if (e.errors) {
      //https://stackoverflow.com/questions/37576685/using-async-await-with-a-foreach-loop
      for (const error of e.errors) {
        //These "errorType" values correspond to a class that extends error in the back end app
        if (error.errorType === 'InactiveUserError') {
          console.log('The user was inactive.  We are logging the user out');
          await logOutWithTeardown(false);
          throw new SyncAborted('Invalid User Account (Inactive)');
        } else if (error.errorType === 'InactiveOrgError') {
          console.log("The user's organization was inactive.  We are logging the user out");
          await logOutWithTeardown(false);
          throw new SyncAborted('Invalid Org Account (Inactive)');
        } else if (error.errorType === 'Function.ResponseSizeTooLarge') {
          //This is the case that handles:
          //Response payload size exceeded maximum allowed payload size (6291556 bytes)
          console.log('Response was too big!');
          throw error;
        } else if (excludedErrorMessages.includes(error.message)) {
          throw new NonReportedError(error.message);
        } else {
          throw error;
        }
      }
    } else {
      // This is a generic error response meaning there was an error on the server
      // To see the server side error check the CloudWatch logs for the resolver function
      console.error(
        'Unhandled errors in GraphQL query\nquery:',
        query,
        '\nvariables:',
        variables,
        '\nerrors:',
        e.errors,
        '\nFull Error',
        logFriendlyObject(e)
      );
    }

    throw new Error('GraphQL query failed');
  }
};

export async function streamToString(stream: ReadableStream | null): Promise<string> {
  if (!stream) {
    return '';
  }
  const reader = stream.getReader();
  const decoder = new TextDecoder();
  let result = '';
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    result += decoder.decode(value, { stream: true });
  }
  return result;
}
