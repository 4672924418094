import { TimeTrackingEvent, TimeTrackingInterface } from '../../interfaces/timeTrackingInterface';
import { v4 as uuid } from 'uuid';
import {
  TimeTrackingEventType,
  WorkLogData,
  WorkLogDataTimePunch,
} from '../../../../../timetracking/timeTrackingConstants';
import { db } from './db';
import { logFriendlyObject } from '@otuvy/common-utils';
import { EnvironmentConfig, getFlag } from '../../../../environmentUtils';
import { liveQuery, Subscription } from 'dexie';

export class DexieTimeTracking implements TimeTrackingInterface {
  async queueEvent({
    workLogId = uuid(),
    punchType = TimeTrackingEventType.UNKNOWN,
    punchTime,
    timeZoneOffset,
    preciseLat,
    preciseLong,
    createdOn,
  }: Partial<TimeTrackingEvent>) {
    if (!getFlag(EnvironmentConfig.TIME_TRACKING)) return;

    try {
      const fullEvent: TimeTrackingEvent = {
        punchId: uuid(),
        workLogId,
        punchTime: punchTime || new Date(),
        timeZoneOffset: timeZoneOffset || new Date().getTimezoneOffset(),
        punchType,
        preciseLat: preciseLat || 0,
        preciseLong: preciseLong || 0,
        createdOn: createdOn || new Date(),
      };

      await db().timeTrackingChangeLog.add(fullEvent);

      let existingWorkLog: WorkLogData | undefined = await db().timeTrackingWorkLog.get(workLogId);
      if (!existingWorkLog) existingWorkLog = { workLogId, punchIn: undefined, punchOut: undefined };

      const newPunch = {
        punchId: fullEvent.punchId,
        punchTime: fullEvent.punchTime,
        timeZoneOffset: fullEvent.timeZoneOffset,
        punchType: fullEvent.punchType,
        address: null,
      };

      if (fullEvent.punchType === TimeTrackingEventType.CLOCK_IN) {
        existingWorkLog.punchIn = newPunch;
      } else if (fullEvent.punchType === TimeTrackingEventType.CLOCK_OUT) {
        existingWorkLog.punchOut = newPunch;
      }

      await db().timeTrackingWorkLog.put(existingWorkLog, workLogId);
    } catch (error) {
      console.warn('Error in attempting to queue time tracking event', logFriendlyObject(error));
    }
  }

  async getEventIdsNeedingUploaded(): Promise<string[]> {
    let eventIds: string[] = [];

    await db().timeTrackingChangeLog.each((r) => {
      eventIds.push(r.punchId);
    });

    return eventIds;
  }

  async getEventById(eventId: string): Promise<TimeTrackingEvent | undefined> {
    const events: TimeTrackingEvent[] = await db().timeTrackingChangeLog.where('punchId').equals(eventId).toArray();

    if (!events || events.length === 0) {
      console.warn(`No events matching PunchID ${eventId}`);
      return undefined;
    }

    if (events && events.length > 1) {
      console.warn(`Muliple events matching PunchID ${eventId}`);
      return undefined;
    }

    return events[0];
  }

  async markEventsAsUploaded(eventIds: string[]) {
    await db().timeTrackingChangeLog.bulkDelete(eventIds);
  }

  async calculateCurrentWorkLog(): Promise<Partial<WorkLogData | undefined>> {
    var currentWorkLog: WorkLogData | undefined;

    await db().timeTrackingWorkLog.each((workLog) => {
      if (
        !workLog.punchOut ||
        workLog.punchOut === null ||
        !workLog.punchOut.punchTime ||
        workLog.punchOut.punchTime === null
      ) {
        if (currentWorkLog && currentWorkLog !== null) {
          console.warn('There are multiple work logs that are potentially current.');

          const oldPunchTime = currentWorkLog?.punchIn?.punchTime?.valueOf();
          const newPunchTime = workLog?.punchIn?.punchTime?.valueOf();

          if (oldPunchTime && newPunchTime && newPunchTime > oldPunchTime) {
            currentWorkLog = workLog;
          }
        } else {
          currentWorkLog = workLog;
        }
      }
    });

    return currentWorkLog;
  }

  async getCurrentWorkLog(): Promise<Partial<WorkLogData | undefined>> {
    return this.calculateCurrentWorkLog();
  }

  async getCurrentWorkLogId(): Promise<string | undefined> {
    const currentWorkLog = await this.getCurrentWorkLog();
    return currentWorkLog ? currentWorkLog.workLogId : undefined;
  }

  localTimezoneOffset = new Date().getTimezoneOffset(); //Do we need to do this at the runtime of the function to account for people traveling?
  enforceTimePunchIsDateAndAdjustOffset(punch: WorkLogDataTimePunch | undefined): WorkLogDataTimePunch | undefined {
    if (!punch) return undefined;

    const punchTime = punch.punchTime ? new Date(punch.punchTime) : null;

    //console.log(`Adjusting timezone offset from ${punch.timeZoneOffset} to ${this.localTimezoneOffset}`);
    if (
      punchTime !== null &&
      punch.timeZoneOffset &&
      punch.timeZoneOffset !== null &&
      this.localTimezoneOffset !== punch.timeZoneOffset
    ) {
      punchTime?.setMinutes(punchTime.getMinutes() + (this.localTimezoneOffset - punch.timeZoneOffset));
    }

    return {
      ...punch,
      punchTime,
      timeZoneOffset: 0,
    };
  }

  async getWorkLogs(): Promise<WorkLogData[]> {
    let workLogs: WorkLogData[] = [];

    await db().timeTrackingWorkLog.each((r) => {
      //enforce that properties that *should* be date objects actually are
      workLogs.push({
        ...r,
        punchIn: this.enforceTimePunchIsDateAndAdjustOffset(r.punchIn),
        punchOut: this.enforceTimePunchIsDateAndAdjustOffset(r.punchOut),
      });
    });

    return workLogs;
  }

  async saveWorkLogs(workLogs: WorkLogData[]): Promise<void> {
    if (!workLogs || workLogs.length === 0) return;

    try {
      /*
        From the Dexie documentation (https://dexie.org/docs/Table/Table.bulkAdd()):
        "bulkAdd() will fail to add any item with same primary key whilst bulkPut () will succeed and update those records as well as the new ones."

        This should allow us to keep local work logs that have more information (a clock out) that the server has not recieved yet
       */
      await db().timeTrackingWorkLog.bulkPut(workLogs);
    } catch (error) {
      console.warn('Error in attempting to save work logs', logFriendlyObject(error));
    }
  }

  async cleanupWorkLogs(): Promise<void> {
    const DELETE_LOGS_OLDER_THAN = 10 * 24 * 60 * 60 * 1000; // 10 days
    const deleteOlderThan = new Date(new Date().getTime() - DELETE_LOGS_OLDER_THAN);

    const oldWorkLogIds: string[] = [];
    await db().timeTrackingWorkLog.each((r: WorkLogData) => {
      const punchInOlder = !r.punchIn || !r.punchIn.punchTime || r.punchIn.punchTime < deleteOlderThan;
      const punchOutOlder = !r.punchOut || !r.punchOut.punchTime || r.punchOut.punchTime < deleteOlderThan;

      if (punchInOlder && punchOutOlder) {
        oldWorkLogIds.push(r.workLogId);
      }
    });

    console.log(`Deleting ${oldWorkLogIds.length} old work logs`);
    if (oldWorkLogIds.length > 0) await db().timeTrackingWorkLog.bulkDelete(oldWorkLogIds);
  }

  async watchForWorkLogChanges(callback: () => void): Promise<{ workLogSubscription: Subscription }> {
    const workLogObservable = liveQuery(() => db().timeTrackingWorkLog.toArray());

    const workLogSubscription = workLogObservable.subscribe({
      next: callback,
      error: (error) => console.error(`Error subscribing to work log observable`, logFriendlyObject(error)), //TODO: handle this better?
    });

    return {
      workLogSubscription,
    };
  }

  async unwatch(subscriptions: { workLogSubscription: Subscription }) {
    subscriptions.workLogSubscription.unsubscribe();
  }
}
