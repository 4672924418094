import {
  parseISO,
  isSameDay,
  isSameMonth,
  isSameYear,
  setHours,
  setMinutes,
  setSeconds,
  formatISO,
  isTomorrow,
  format,
  isToday,
  isYesterday,
  isBefore,
  addYears,
  addMinutes,
} from 'date-fns';
import { DateTime } from 'luxon';
import { RRule } from 'rrule';
import { TaskDO } from '../../../utils/state/model/interfaces/displayObjects';
import { implementLocalDateToUtc, implementUtcDateToLocal } from './recurrenceUtils';
import i18n from '../../../i18n';
import { formatTime } from '../../timeTracking/utils/timeUtils';
type CalendarISODate = string | undefined | null | string[];

// Helper function to set initial calendar date
export const initialCalendarDate = (tasksArr: TaskDO[]): string | null => {
  const compareArr = tasksArr.filter((task) => task.dueDate?.valueOf() === tasksArr[0].dueDate?.valueOf());

  if (tasksArr.length === compareArr.length && tasksArr[0]?.dueDate) {
    return formatISO(offsetDateToLocalTime(tasksArr[0].dueDate, tasksArr[0].timezone as string | null));
  }

  return null;
};

export const initialMultiCalendarDate = (tasksArr: TaskDO[]) => {
  if (!initialCalendarDate(tasksArr)) return null;
  if (!tasksArr[0].recurrence) return null;

  const rule = RRule.fromString(tasksArr[0].recurrence);

  const datesForRecurrence = rule
    .between(implementLocalDateToUtc(new Date()), implementLocalDateToUtc(addYears(new Date(), 5)))
    .map((date) => {
      const taskDate = implementUtcDateToLocal(date);
      return offsetDateToLocalTime(taskDate, rule.options.tzid);
    });
  const datesToCalendarFormat = datesForRecurrence.map((date) => formatISO(date, { representation: 'date' }));
  return datesToCalendarFormat;
};

export const initialRecurrenceState = (tasksArr: TaskDO[]) => {
  if (!initialCalendarDate(tasksArr)) return null;
  if (!tasksArr[0].recurrence) return null;

  const rule = RRule.fromString(tasksArr[0].recurrence);
  return rule;
};

export const initialRecurrenceOptionState = (tasksArr: TaskDO[]) => {
  if (!tasksArr[0].recurrence) return 'none';

  const rule = RRule.fromString(tasksArr[0].recurrence);

  if (rule.origOptions.interval) return 'custom';

  switch (rule.origOptions.freq) {
    case RRule.DAILY:
      return 'daily';
    case RRule.WEEKLY:
      return rule.options.byweekday.length > 1 ? 'weekdays' : 'weekly';
    case RRule.MONTHLY:
      return rule.origOptions.byweekday ? 'monthly-weekday' : 'monthly';
    case RRule.YEARLY:
      return 'yearly';
  }
  return 'none';
};

// Helper function to set the initial
export const initialAddTimeState = (tasksArr: TaskDO[]) => {
  const date = initialCalendarDate(tasksArr);
  return date && !isDefaultTime(parseISO(date)) ? true : false;
};

export const initialTimezoneState = (tasksArr: TaskDO[]): string => {
  const compareArr = tasksArr.filter((task) => task.timezone === tasksArr[0].timezone);

  if (tasksArr.length === compareArr.length && tasksArr[0]?.timezone) {
    return tasksArr[0].timezone;
  }

  return DateTime.local().zoneName!;
};

// Helper funtion to disable past dates in the calendar
export const isCalendarInteractive = (dateString: string) => {
  const calendarDate = parseISO(dateString);
  const currentDate = new Date();
  return !isBefore(calendarDate, currentDate) || isSameDay(calendarDate, currentDate);
};

// Check if two dates are the same without taking time into account
export const areSameDates = (date1: CalendarISODate, date2: CalendarISODate) => {
  const dateOneObject = parseISO(date1 as string);
  const dateTwoObject = parseISO(date2 as string);

  if (
    isSameDay(dateOneObject, dateTwoObject) &&
    isSameMonth(dateOneObject, dateTwoObject) &&
    isSameYear(dateOneObject, dateTwoObject)
  ) {
    return true;
  }

  return false;
};

// Helper function to offset the date to display the correct time
export const offsetDateToLocalTime = (date: Date, tzid: string | null): Date => {
  if (tzid === null || tzid === DateTime.local().zoneName) return date;
  const taskTimezoneOffset = DateTime.fromJSDate(date).setZone(tzid).offset;
  const localOffset = new Date().getTimezoneOffset();
  const offset = taskTimezoneOffset + localOffset;
  return addMinutes(date, offset);
};

// Helper function to set a default time to the date(11:59:59pm)
export const setDefaultTime = (date: Date) => {
  const defaultHour = setHours(date, 23);
  const defaultMinutes = setMinutes(defaultHour, 59);
  const defaultSeconds = setSeconds(defaultMinutes, 59);
  return formatISO(defaultSeconds);
};

export const updateCustomTime = (calendarDate: Date, timePickerDate: Date) => {
  const defaultHour = setHours(calendarDate, timePickerDate.getHours());
  const defaultMinutes = setMinutes(defaultHour, timePickerDate.getMinutes());
  const defaultSeconds = setSeconds(defaultMinutes, timePickerDate.getSeconds());
  return formatISO(defaultSeconds);
};

export const setCustomTime = (date: Date) => {
  if (!isDefaultTime(date)) return formatISO(date);
  const defaultHour = setHours(date, 12);
  const defaultMinutes = setMinutes(defaultHour, 0);
  const defaultSeconds = setSeconds(defaultMinutes, 0);
  return formatISO(defaultSeconds);
};

export const isDefaultTime = (date: Date) => {
  return date.getHours() === 23 && date.getMinutes() === 59 && date.getSeconds() === 59;
};

//Check when if the due date is ...
export const shouldDisplayNaturalText = (dueDate: Date) => {
  if (isToday(dueDate)) return `${i18n.t('today')} ${!isDefaultTime(dueDate) ? `${formatTime(dueDate)}` : ''}`;
  if (isTomorrow(dueDate)) return `${i18n.t('tomorrow')} ${!isDefaultTime(dueDate) ? `${formatTime(dueDate)}` : ''}`;
  if (isYesterday(dueDate)) return `${i18n.t('yesterday')} ${!isDefaultTime(dueDate) ? `${formatTime(dueDate)}` : ''}`;
};

//Due remove the time from the due date text if is the default time
export const dueDateText = (date: Date, tzid: string | null = null) => {
  const dueDate = offsetDateToLocalTime(date, tzid);
  const displayTimezoneText = tzid && tzid !== DateTime.local().zoneName;
  const timeZoneText = tzid ? DateTime.local().setZone(tzid).offsetNameShort : '';

  if (shouldDisplayNaturalText(dueDate))
    return shouldDisplayNaturalText(dueDate) + (displayTimezoneText ? ` ${timeZoneText}` : '');

  if (dueDate.getHours() === 23 && dueDate.getMinutes() === 59 && dueDate.getSeconds() === 59)
    return format(dueDate, 'L/dd/yy') + (displayTimezoneText ? ` ${timeZoneText}` : '');

  return format(dueDate, 'L/dd/yy hh:mm a') + (displayTimezoneText ? ` ${timeZoneText}` : '');
};

// Check if task is past due date
export const dueDateTextStyle = (task: TaskDO) => {
  // For incompleted tasks
  if (!task.isCompleted && task.dueDate) {
    return isBefore(task.dueDate, new Date()) ? 'error' : 'heavy';
  }

  //TODO: This works but we still need to way for the backend to display the compledtedDate property
  // For completed tasks
  // if(task.isCompleted && task.dueDate){
  //   return isBefore(parseISO(task.dueDate as string), fromUnixTime(task.completedStatus[0].when / 1000)) ? 'error' : 'heavy'
  // }

  return 'light';
};

export const getNthFromDate = (date: Date) => {
  return Math.ceil(date.getDate() / 7);
};
