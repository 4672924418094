import { addDays, addYears, formatISO, isBefore, parseISO } from 'date-fns';
import { DateTime } from 'luxon';
import { useReducer } from 'react';
import { RRule } from 'rrule';
import { TaskDO } from '../../../utils/state/model/interfaces/displayObjects';
import {
  initialAddTimeState,
  initialCalendarDate,
  initialMultiCalendarDate,
  initialRecurrenceOptionState,
  initialRecurrenceState,
  initialTimezoneState,
  offsetDateToLocalTime,
  setCustomTime,
  setDefaultTime,
  updateCustomTime,
} from '../utils/calendarUtils';
import { createRecurrenceRule, implementLocalDateToUtc, implementUtcDateToLocal } from '../utils/recurrenceUtils';

type CalendarISODate = string | undefined | null | string[];

export enum ActionType {
  SET_CALENDAR = 'SET_CALENDAR',
  SET_TIMEPICKER = 'SET_TIMEPICKER',
  SET_RRULE = 'SET_RRULE',
  TOGGLE_TIMEPICKER = 'TOGGLE_TIMEPICKER',
  TOGGLE_TIMEZONE_PICKER = 'TOGGLE_TIMEZONE_PICKER',
  SET_TIMEZONE = 'SET_TIMEZONE',
}

interface State {
  calendarValue: CalendarISODate | null;
  multiCalendarValue: string[] | null;
  timePickerValue: CalendarISODate | null;
  isTimePickerOn: boolean;
  recurrence: RRule | null;
  recurrenceOption: string;
  timezone: string;
  isTimezonePickerOn: boolean;
}

type Action =
  | { type: 'SET_CALENDAR'; payload: { calendarValue: CalendarISODate } }
  | { type: 'TOGGLE_TIMEPICKER'; payload: {} }
  | { type: 'SET_TIMEPICKER'; payload: { timePickerValue: CalendarISODate } }
  | { type: 'SET_RRULE'; payload: { recurrence: RRule | null; recurrenceOption: string } }
  | { type: 'TOGGLE_TIMEZONE_PICKER'; payload: {} }
  | { type: 'SET_TIMEZONE'; payload: { timezone: string } };

function getDatesFromRecurrenceRule(rule: RRule, timezone: string | null) {
  const datesFromRule = rule
    .between(implementLocalDateToUtc(new Date()), implementLocalDateToUtc(addYears(new Date(), 5)))
    .map((date) => implementUtcDateToLocal(date));
  const datesToCalendarFormat = datesFromRule.map((date) =>
    formatISO(offsetDateToLocalTime(date, timezone), { representation: 'date' })
  );
  return datesToCalendarFormat;
}

function initializeCalendarViewState(tasks: TaskDO[]): State {
  const calendarValue = initialCalendarDate(tasks);
  const multiCalendarValue = initialMultiCalendarDate(tasks);
  const timePickerValue = initialCalendarDate(tasks);
  const isTimePickerOn = initialAddTimeState(tasks);
  const recurrence = initialRecurrenceState(tasks);
  const recurrenceOption = initialRecurrenceOptionState(tasks);
  const isTimezonePickerOn = tasks[0].timezone !== DateTime.local().zoneName && isTimePickerOn ? true : false;
  const timezone = initialTimezoneState(tasks);

  return {
    calendarValue,
    multiCalendarValue,
    timePickerValue,
    isTimePickerOn,
    recurrence,
    recurrenceOption,
    timezone,
    isTimezonePickerOn,
  };
}

function calendarReducer(state: State, action: Action) {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SET_CALENDAR:
      if (isBefore(parseISO(payload.calendarValue as string), addDays(new Date(), -1))) {
        return {
          ...state,
        };
      }
      if (state.isTimePickerOn) {
        return {
          ...state,
          calendarValue: updateCustomTime(
            parseISO(payload.calendarValue as string),
            parseISO(state.timePickerValue as string)
          ),
        };
      } else {
        return { ...state, calendarValue: setDefaultTime(parseISO(payload.calendarValue as string)) };
      }
    case ActionType.TOGGLE_TIMEPICKER:
      if (!state.calendarValue) return state;
      if (state.isTimePickerOn) {
        return {
          ...state,
          isTimePickerOn: false,
          timePickerValue: setDefaultTime(parseISO(state.calendarValue as string)),
          calendarValue: setDefaultTime(parseISO(state.calendarValue as string)),
          isTimezonePickerOn: false,
          timezone: DateTime.local().zoneName,
          recurrence:
            state.recurrence && state.recurrenceOption
              ? createRecurrenceRule(
                  state.recurrence,
                  state.recurrenceOption,
                  parseISO(setDefaultTime(parseISO(state.calendarValue as string)))
                )
              : null,
        };
      } else {
        return {
          ...state,
          isTimePickerOn: true,
          timePickerValue: setCustomTime(parseISO(state.calendarValue as string)),
          calendarValue: setCustomTime(parseISO(state.calendarValue as string)),
          recurrence:
            state.recurrence && state.recurrenceOption
              ? createRecurrenceRule(
                  state.recurrence,
                  state.recurrenceOption,
                  parseISO(setCustomTime(parseISO(state.calendarValue as string)))
                )
              : null,
        };
      }
    case ActionType.SET_TIMEPICKER:
      if (state.recurrence && state.recurrenceOption)
        return {
          ...state,
          timePickerValue: payload.timePickerValue,
          calendarValue: payload.timePickerValue,
          recurrence: createRecurrenceRule(
            state.recurrence,
            state.recurrenceOption,
            parseISO(payload.timePickerValue as string),
            state.timezone
          ),
        };
      return { ...state, timePickerValue: payload.timePickerValue, calendarValue: payload.timePickerValue };
    case ActionType.SET_RRULE:
      return {
        ...state,
        recurrence: payload.recurrence,
        recurrenceOption: payload.recurrenceOption,
        multiCalendarValue: payload.recurrence
          ? getDatesFromRecurrenceRule(payload.recurrence, state.timezone as string | null)
          : null,
      };
    case ActionType.TOGGLE_TIMEZONE_PICKER:
      return {
        ...state,
        isTimezonePickerOn: !state.isTimezonePickerOn,
        timezone: DateTime.local().zoneName,
        recurrence:
          state.recurrence && state.recurrenceOption
            ? createRecurrenceRule(
                state.recurrence,
                state.recurrenceOption,
                parseISO(state.calendarValue as string),
                DateTime.local().zoneName as string
              )
            : null,
      };
    case ActionType.SET_TIMEZONE:
      return {
        ...state,
        timezone: payload.timezone,
        recurrence:
          state.recurrence && state.recurrenceOption
            ? createRecurrenceRule(
                state.recurrence,
                state.recurrenceOption,
                parseISO(state.calendarValue as string),
                payload.timezone
              )
            : null,
      };
    default:
      return state;
  }
}

export const useCalendarView = (tasks: TaskDO[]) => {
  return useReducer(calendarReducer, null, () => initializeCalendarViewState(tasks) as never);
};
